import React from 'react';
import { navigate } from 'gatsby';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isReady: false
        };
    }

    componentDidMount() {
        this.setState({ isReady: true });
    }

    render() {
        // Gatsby build step with fail because 'window' used in the navigate will be undefined, we need to check that it's been rendered in browser first
        const redirect = this.state.isReady ? <div>{navigate('/')}</div> : <div />;

        return <div>{redirect}</div>;
    }
}

export default Index;
